import {ref, update} from 'firebase/database';
import {firebaseDb} from '../config/firebase';
import {v4 as uuidv4} from 'uuid';

export const joinWaitlist = async (email) => {
  try {
    const emailsRef = ref(firebaseDb, `waitlist/emails`);
    const res = await update(emailsRef, {
      [uuidv4()]: {
        email: email,
        time_subscribed: new Date().toGMTString(),
      },
    });
    return res;

  } catch (err) {
    console.log(err);
    return err;
  }
};
export const contactUs = async (data) => {
  const inboxRef = ref(firebaseDb, `inbox/${uuidv4()}`);
  await update(inboxRef, data);
};


