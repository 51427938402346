import {lazy, Suspense} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {FooterComponent} from './components/footer';
import {NavigationComponent} from './components/navigation';
import {StoreContextProvider} from './store/store-context';
import {StyleProvider} from './theme/provider';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ReactNotifications} from 'react-notifications-component';
import {PageLoader} from './components/pageloader/pageloader.component';
import "./index.css"
import ContactUsProvider from './contactusProvider/provider';

const LazyComingSoonPage = lazy(() =>
  import('./pages/coming-soon').then((module) => ({
    default: module.ComingSoon,
  })),
);
const OurFarmsPage = lazy(() =>
  import("./pages/farms").then((module) => ({
    default: module.OurFarms,
  }))
);
const BlogPage = lazy(() =>
  import("./pages/blog/blog").then((module) => ({
    default: module.Blog,
  }))
);
const BlogContentPage = lazy(() =>
  import("./pages/blogContent").then((module) => ({
    default: module.BlogContent,
  }))
);
const LazyPrivacyPolicy = lazy(() =>
  import("./pages/privacy-policy").then((module) => ({
    default: module.PrivacyPolicyPage,
  }))
);
const LazyTermsAndConditions = lazy(() =>
  import("./pages/terms-and-conditions").then(
    (module) => ({
      default: module.TermsAndConditions,
    })
  )
);
const OurFarmPopUp = lazy(() =>
  import("./pages/farms/pages/ourFarmPopUp").then((module) => ({
    default: module.OurFarmPopUp,
  }))
);

export const RedirectTo = ({redirectTo}) => (
  <Navigate to={redirectTo} replace />
);

function App() {
  return (
    <StoreContextProvider>
      <ReactNotifications />
      <Suspense fallback={<PageLoader />}>
        <StyleProvider>
          <ContactUsProvider>
            <BrowserRouter>
              <NavigationComponent />
              <Routes>
                <Route path="/">
                  <Route index element={<LazyComingSoonPage />} />
                  <Route
                    path="privacy-policy"
                    element={<LazyPrivacyPolicy />}
                  />
                  <Route
                    path="terms-and-conditions"
                    element={<LazyTermsAndConditions />}
                  />
                  <Route  path="farms" element={<OurFarmsPage />}>
                    <Route path=":farm_slug" element={<OurFarmPopUp />} />
                  </Route>
                  <Route path="blog" element={<BlogPage />}></Route>
                  <Route
                    path="blog/:blogSection/:blogTopic"
                    element={<BlogContentPage />}
                  />
                </Route>
                <Route path="*" element={<RedirectTo redirectTo="/" />} />
              </Routes>
              <FooterComponent />
            </BrowserRouter>
          </ContactUsProvider>
        </StyleProvider>
      </Suspense>
    </StoreContextProvider>
  );
}

export default App;
